@import '../../../assets/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  will-change: opacity;
  text-align: start;
}

.sheet {
  min-height: 10rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: calc(env(safe-area-inset-bottom) - 0.75rem);
}
