@import '../../../assets/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.customBtn.roundedBtn {
  height: 40px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $gray-600;
  border: solid 1px $gray-600;
  flex: none;
  &:focus {
    box-shadow: none !important;
  }
}
