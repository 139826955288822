@import '../../../../assets/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.container {
  margin: 0 -1.5rem;
  padding: 0rem 1.5rem 0.5rem !important;
}

.element {
  flex: none;
  display: inline-flex;
}

.card {
  justify-content: center;
  height: 52px;
  min-height: 52px;
  min-width: 100%;
  margin-bottom: 0.688rem;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(188, 192, 196, 0.4);
}

@media (min-width: 576px) {
  .card {
    margin-right: 0.688rem;
  }
}

.footer.footerTag {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 3px 0;
  background-color: $secondary-light-blue;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.viewPricing.box {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 3px 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-decoration: underline;
}
