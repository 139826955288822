// @use '~bootstrap/scss/functions';
// @use '~bootstrap/scss/mixins';
// @use '~bootstrap/scss/variables';
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
// fonts
$enable-responsive-font-sizes: true;
$font-family-arabic: 'Tajawal', sans-serif;
$line-height-base: 1.57;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.375; //22px
$h2-font-size: $font-size-base * 1.25; // 20px
$h3-font-size: $font-size-base; // 16px
$h4-font-size: $font-size-base * 0.875; // 14px
$h5-font-size: $font-size-base * 0.75; // 12px

$font-weight-bold: 500;
$headings-line-height: 1.57;

// colors & theme
$blue: #3e48a6;
$red: #c83c3e;
$green: #dd2a5b;
$pink: #ec9ce6;
$gray: #edeef3;
$yellow: #ffc042;
$orange: #f7430a;
$light-pink: #fff4fe;
$light-green: #e2f7f6;
$light-blue: #e8eaff;
$separator-gray: #f2f3f8;
$separator-gray-light: #eeeff4;
$secondary-gray: #e9edf4;

$dark-blue: #111135;
$secondary-light-blue: #e8eaff;
$dusky-blue: #5059ae;
$gray-500: #f2f4f7;
$gray-600: #b6bcd1;
$gray-800: #585871;
$gray-bg: #fcfcfc;

// body
$body-color: $dark-blue;
$body-bg: #fff;

// list groups
$list-group-bg: #fff;
$list-group-border-color: $separator-gray;
$list-group-item-padding-x: 1rem;
$list-group-item-padding-y: 1.25rem;

// buttons & inputs
$btn-border-radius-lg: 8px;
$font-size-lg: $font-size-base * 0.875;
$input-btn-padding-y-lg: 0.65rem;
$btn-font-weight: $font-weight-bold;
$input-btn-font-size: 0.875rem;

$btn-border-radius-sm: 6px;
$font-size-sm: $font-size-base * 0.75;
$input-btn-padding-y-sm: 6px;
$input-btn-padding-x-sm: 12px;

// Forms
$input-color: $dark-blue !important;
$input-border-color: $separator-gray-light;
$input-box-shadow: none;
$input-btn-focus-width: 0;

// cards
$card-cap-bg: $secondary-light-blue;
$card-border-radius: 12px;
$card-spacer-y: 8px;
$card-spacer-x: 16px;
$card-border-width: 0;

// grid
$grid-gutter-width: 32px;

// Alerts
$alert-padding-y: 1rem;
$alert-padding-x: 1rem;
// $alert-bg-level: 0;
// $alert-border-level:                -9 !default;
// $alert-color-level:                 6 !default;

// custom control
$custom-control-cursor: pointer;
$custom-control-indicator-size: 1.25rem !default;
$custom-control-indicator-border-color: $gray-600;
$custom-control-indicator-border-width: 0.9px;
$custom-control-indicator-checked-bg: #dd2a5b;
$custom-control-indicator-checked-disabled-bg: rgba($green, 0.5) !default;
$custom-control-indicator-checked-border-color: #dd2a5b;
$custom-control-indicator-checked-color: red;
$custom-control-indicator-focus-box-shadow: transparent; //0 0 0 0.2rem rgba($green, 0.25);
$custom-control-indicator-focus-border-color: transparent; 
$custom-control-indicator-active-bg: transparent;
$custom-checkbox-indicator-border-radius: 2.3px;
$custom-control-indicator-bg: #e9edf1;
$custom-control-indicator-border-color: #889db4;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg width=' 14' height=' 10' viewBox=' 0 0 14 10' fill=' none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> <path fill-rule='evenodd' clip-rule='evenodd' d='M13.1854 0.6125C13.3381 0.7625 13.3381 0.9875 13.1854 1.1375L4.78541 9.3875C4.70904 9.4625 4.61359 9.5 4.51813 9.5C4.42268 9.5 4.32722 9.4625 4.25086 9.3875L0.814497 6.0125C0.661769 5.8625 0.661769 5.6375 0.814497 5.4875C0.967224 5.3375 1.19631 5.3375 1.34904 5.4875L4.51813 8.6L12.6509 0.6125C12.8036 0.4625 13.0327 0.4625 13.1854 0.6125Z' fill='white' stroke='white' stroke-width='2.5625' /> </svg>");

$custom-radio-indicator-icon-checked: url("data:image/svg+xml,  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 20 20' fill='none'> <mask id='mask0_2250_2261' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='30' height='30'> <path fill-rule='evenodd' clip-rule='evenodd' d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z' fill='white'/> </mask> <g mask='url(#mask0_2250_2261)'> <rect x='-2' y='-2' width='24' height='24' fill='white'/> </g> </svg> ");
// theme-colors
$theme-colors: (
  'primary': $blue,
  'secondary': $green,
  'success': $green,
  'danger': $orange,
  'warning': $yellow,
  // info
  'light': $gray-bg,
  'dark': $dark-blue,
  // Custom
  'blue': $blue,
  'green': $green,
  'red': $red,
  'pink': $pink,
  'yellow': $yellow,
  'orange': $orange,
  'separator-gray': $separator-gray,
  'gray-dark': $gray-800,
  'gray-bg': $gray-bg,
  'cloudy-blue': $gray-600,
  'white': white,
);

// spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
    // 4px
  ),
  2: (
    $spacer * 0.5,
    // 8px
  ),
  3: (
    $spacer * 0.75,
    // 12px
  ),
  4: (
    $spacer * 0.875 // 14px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  ),
  5: $spacer,
  // 16px
  6:
    (
      $spacer * 1.25,
      // 20px
    ),
  7: (
    $spacer * 1.5,
    //24px
  ),
  8: (
    $spacer * 1.625,
    // 26px
  ),
  9: (
    $spacer * 2.125,
    // 34px
  ),
  10: (
    $spacer * 2.1875,
    // 35px
  ),
  18: (
    $spacer * 1.125 //18px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  ),
);
