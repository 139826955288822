@mixin important-spacing-rtl($property, $property-rtl, $value) {
  #{$property}: $value !important;

  [dir='rtl'] & {
    #{$property-rtl}: $value !important;
  }
}

@supports (not (margin-inline-start: 0)) or (not (padding-inline-start: 0)) {
  @each $size, $length in $spacers {
    .margin-start-#{$size} {
      @include important-spacing-rtl(margin-left, margin-right, $length);
    }
    .margin-end-#{$size} {
      @include important-spacing-rtl(margin-right, margin-left, $length);
    }
    .padding-start-#{$size} {
      @include important-spacing-rtl(padding-left, padding-right, $length);
    }
    .padding-end-#{$size} {
      @include important-spacing-rtl(padding-right, padding-left, $length);
    }
  }
  .margin-start-auto {
    @include important-spacing-rtl(margin-left, margin-right, auto);
  }
  .margin-end-auto {
    @include important-spacing-rtl(margin-right, margin-left, auto);
  }
}
