html[lang="ar"] {
  font-family: $font-family-arabic;

  body {
    text-align: right;
  }
}

html, body {
  margin: 0; 
  height: 100vh; 
  overflow: hidden;
}

// only ios specific code
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  // TODO: need to do it based on the partner.
  iframe[name='intercom-messenger-frame'] {
    height: calc(100% - 85px) !important;
    margin-top: 85px !important;
  }

  .intercom-messenger-frame {
    background-color: #DC2B5B !important;
  }
  // TODO: need to do it based on the partner.
}


.card {
  box-shadow: 0 2px 4px -1px rgba(17, 17, 53, 0.04), 0 1px 10px 0 rgba(17, 17, 53, 0.04),
    0 4px 5px 0 rgba(17, 17, 53, 0.05);
}

.text-start {
  text-align: start;
  padding-top: env(safe-area-inset-top);
}

.text-pre-line {
  white-space: pre-line;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  display: none;
  scrollbar-width: none;
  /* Firefox */
  scrollbar-width: none;
}


body .list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px;
}

.PhoneInputInput {
  @extend .form-control;
  direction: ltr;
}

.centered-element {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

button.hitSlop {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 1rem;
  margin: -1em;
}

a.hitSlop {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 1rem;
  margin: -1em;
}

input.input-no-outline {
  border-width: 0px 0px 1px 0px !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: $gray-bg;

  &:focus {
    background-color: $gray-bg;
  }
}

.input-label-sm {
  color: $gray-800;
  font-size: 0.75rem !important;
  font-weight: 300;
  margin-bottom: 0rem !important;
}

label.form-label {
  font-size: 0.875rem;
}

@each $size,
$length in $spacers {
  .margin-start-#{$size} {
    margin-inline-start: $length !important;
  }

  .margin-end-#{$size} {
    margin-inline-end: $length !important;
  }

  .padding-start-#{$size} {
    padding-inline-start: $length !important;
  }

  .padding-end-#{$size} {
    padding-inline-end: $length !important;
  }
}

.transform-180 {
  transform: rotate(180deg);
}

.transform-270 {
  transform: rotate(270deg);
}

.margin-start-auto {
  margin-inline-start: auto;
}

.margin-end-auto {
  margin-inline-end: auto;
}

div.Toastify__toast-container--top-center {
  top: 100px;
}

.splash-image {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.grecaptcha-badge { 
  visibility: hidden;
}